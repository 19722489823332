import React, { memo } from 'react';
import Icon from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

const ReturnGlyph = () => (
	<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			width="16"
			height="12"
			rx="2"
			fill={token('color.background.accent.gray.bolder', '#626F86')}
		/>
		<path
			d="M12.375 3.1875V4.4375C12.375 6.33597 10.836 7.875 8.9375 7.875H3.9375C3.41974 7.875 3 7.45526 3 6.9375C3 6.41974 3.41974 6 3.9375 6H8.9375C9.8005 6 10.5 5.30045 10.5 4.4375V3.1875C10.5 2.66974 10.9197 2.25 11.4375 2.25C11.9553 2.25 12.375 2.66974 12.375 3.1875Z"
			fill={token('color.text.inverse', '#FFFFFF')}
		/>
		<path
			d="M4.60041 7.60041L6.78787 5.41291C7.154 5.0468 7.154 4.4532 6.78787 4.08708C6.42175 3.72097 5.82825 3.72097 5.46208 4.08708L3.27458 6.27459C2.90847 6.6407 2.90847 7.2343 3.27458 7.60041C3.6407 7.96652 4.2343 7.96652 4.60041 7.60041Z"
			fill={token('color.text.inverse', '#FFFFFF')}
		/>
		<path
			d="M4.60041 6.27458L6.78787 8.46208C7.154 8.82825 7.154 9.42175 6.78787 9.78787C6.42175 10.154 5.82825 10.154 5.46208 9.78787L3.27458 7.60041C2.90847 7.2343 2.90847 6.6407 3.27458 6.27458C3.6407 5.90847 4.2343 5.90847 4.60041 6.27458Z"
			fill={token('color.text.inverse', '#FFFFFF')}
		/>
	</svg>
);

const ReturnIcon = () => <Icon glyph={ReturnGlyph} label="" />;

export default memo(ReturnIcon);
