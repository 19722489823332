import React from 'react';
import ButtonOld from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import { Box, xcss, Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messages from './messages';
import ReturnIcon from './return-icon';

type Props = {
	onClick: () => void;
	isEmpty: boolean;
	hasValidationError: boolean;
};

export const CreateButton = ({ onClick, isEmpty, hasValidationError }: Props) => {
	const { formatMessage } = useIntl();
	const isDisabled = isEmpty || hasValidationError;
	let tooltipContent = formatMessage(messages.createButtonTooltip, {
		icon: () => <ReturnIcon />,
	});

	if (isEmpty) {
		tooltipContent = formatMessage(messages.createButtonTooltipContentEmpty);
	} else if (hasValidationError) {
		tooltipContent = undefined;
	}

	return isVisualRefreshEnabled() ? (
		<Box xcss={createButtonStyles}>
			<Tooltip content={tooltipContent}>
				<IconButton
					spacing="compact"
					type="submit"
					isDisabled={isDisabled}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						onClick();
					}}
					icon={() => (
						<Text color="inherit" as="span">
							⏎
						</Text>
					)}
					label={formatMessage(messages.createButtonLabel)}
				/>
			</Tooltip>
		</Box>
	) : (
		<Box xcss={createButtonStyles}>
			<Tooltip content={tooltipContent}>
				<ButtonOld
					appearance="primary"
					spacing="compact"
					type="submit"
					isDisabled={isDisabled}
					onClick={(e: React.MouseEvent) => {
						e.preventDefault();
						onClick();
					}}
				>
					{formatMessage(messages.createButtonLabel)}
				</ButtonOld>
			</Tooltip>
		</Box>
	);
};
const createButtonStyles = xcss({
	marginLeft: 'space.100',
});
