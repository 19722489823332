import { closeICC } from './close-icc';
import { setHasCreatedIssueICC } from './set-has-created-issue-icc';
import { setTriggerReason } from './set-trigger-reason';
import { toggleKeepIccOpen } from './toggle-keep-icc-open';

export const actions = {
	toggleKeepIccOpen,
	setHasCreatedIssueICC,
	setTriggerReason,
	closeICC,
} as const;
