import type {
	Attachment,
	MediaConfig,
} from '@atlassian/jira-card-cover-manager/src/common/cover-media.types';
import type {
	CardCoversRestApiResponse,
	MobileMedia,
	MobileCardMedia,
} from '@atlassian/jira-software-board-uif-types';
import type { IssueMediaCollection, IssueMedia } from '../../model/issue/issue-media-types';
import type { IssueId } from '../../model/issue/issue-types';

export const transformMediaDataForSingleIssue = (
	cardMedia: MobileCardMedia | Attachment,
	media: MobileMedia | MediaConfig,
): IssueMedia => ({
	attachmentId: String(cardMedia.attachmentId),
	attachmentMediaApiId: cardMedia.mediaApiFileId,
	endpointUrl: media.endpointUrl,
	clientId: media.clientId,
	isHiddenByUser: false,
	token: cardMedia.mediaReadToken,
});

export const transformCardCoversRestApiResponse = (
	cardCoversResponse: CardCoversRestApiResponse,
	issueIdsFromRequest: IssueId[],
): IssueMediaCollection =>
	issueIdsFromRequest.reduce(
		(map, issueId): IssueMediaCollection =>
			Object.assign<IssueMediaCollection, IssueMediaCollection>(map, {
				[String(issueId)]:
					issueId in cardCoversResponse.cardMedia
						? transformMediaDataForSingleIssue(
								cardCoversResponse.cardMedia[Number(issueId)],
								cardCoversResponse.media,
							)
						: null,
			}),
		{},
	);
