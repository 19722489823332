import React from 'react';
import CustomIcon, { SVG, type CustomIconProps } from '../../../../../common/ui/custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const JiraGlyph: React.FunctionComponent = () => (
	<SVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1159_30547)">
			<path
				d="M20.2546 3H11.0742C11.0742 5.24582 12.9315 7.07382 15.2134 7.07382H16.9115V8.66678C16.9115 10.9126 18.7688 12.7406 21.0506 12.7406V3.78343C21.0506 3.33948 20.7057 3 20.2546 3Z"
				fill="#2684FF"
			/>
			<path
				d="M15.7175 7.4917H6.53711C6.53711 9.73752 8.39442 11.5655 10.6763 11.5655H12.3744V13.1846C12.3744 15.4304 14.2317 17.2584 16.5135 17.2584V8.27513C16.5135 7.8573 16.1686 7.4917 15.7175 7.4917Z"
				fill="url(#paint0_linear_1159_30547)"
			/>
			<path
				d="M11.1804 12.0093H2C2 14.2551 3.85731 16.0831 6.13915 16.0831H7.83726V17.6761C7.83726 19.9219 9.69457 21.7499 11.9764 21.7499V12.7927C11.9764 12.3488 11.605 12.0093 11.1804 12.0093Z"
				fill="url(#paint1_linear_1159_30547)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_1159_30547"
				x1="16.3182"
				y1="7.51424"
				x2="12.479"
				y2="11.5369"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.176" stop-color="#0052CC" />
				<stop offset="1" stop-color="#0C66E4" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1159_30547"
				x1="12.0362"
				y1="12.0393"
				x2="7.59319"
				y2="16.4316"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.176" stop-color="#0052CC" />
				<stop offset="1" stop-color="#2684FF" />
			</linearGradient>
			<clipPath id="clip0_1159_30547">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</SVG>
);
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

const JiraIcon: React.FunctionComponent<CustomIconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <JiraGlyph />} />
);

export default JiraIcon;
