import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';

interface Props {
	rapidViewId: number;
	classicLocationless?: boolean;
}

// TODO: FEBA-214 - convert to use EntryPoint (see https://hello.atlassian.net/wiki/spaces/UAF/pages/2333138307/Tutorial+How+to+create+an+EntryPoint)
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy(() =>
	import(/* webpackChunkName: "async-locationless-dialog" */ './index').then(
		({ LocationlessDialogApp }) => LocationlessDialogApp,
	),
);

export const AsyncLocationlessDialog = (props: Props) => (
	<JSErrorBoundary
		id="bundle-async-locationless-dialog"
		teamName="a4t-pandora"
		packageName="jiraBoardLocationDialogsLocationless"
	>
		<Placeholder name="locationless-dialog-app" fallback={null}>
			{/* eslint-disable-next-line jira/deprecations/no-base-url */}
			<LazyComponent baseUrl="" {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
