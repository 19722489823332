import React, { useContext } from 'react';
import Button from '@atlaskit/button/new';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useIntl } from '@atlassian/jira-intl';
import { StandupEntryPointContext } from '../../../controllers/context-provider';
import { useStandupStoreActions } from '../../../controllers/store';
import messages from './messages';

export const StartStandupButton = () => {
	const { formatMessage } = useIntl();
	const { toggleActiveStandup } = useStandupStoreActions();
	const { entryPointActions } = useContext(StandupEntryPointContext);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	return (
		<Button ref={triggerRef} onClick={() => toggleActiveStandup()}>
			{formatMessage(messages.startStandupButtonNonfinal)}
		</Button>
	);
};
