import React, { useEffect, type ReactNode } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { INSIGHTS_PANEL } from '@atlassian/jira-insights-common-constants/src/common/constants/analytics';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { EXPERIMENT_NAME } from '../../common/constants';
import { useIsEligibleAutoOpenInsightsPanel } from '../../services/is-eligible-auto-open-insights-panel';
import {
	useAutoOpenTimestampActions,
	useAutoOpenTimestampState,
} from '../../services/is-eligible-auto-open-insights-panel/store';

export interface AutoOpenInsightsPanelIfEligibleProps {
	onToggle: (analytics?: UIAnalyticsEvent, experienceId?: string) => void;
	isOpen: boolean;
	experienceId: string;
}

export default function AutoOpenInsightsPanelIfEligible({
	onToggle,
	isOpen,
	experienceId,
}: AutoOpenInsightsPanelIfEligibleProps) {
	return (
		<JSErrorBoundary
			id="jptAutoOpenInsightsPanel"
			packageName="jiraJswPresentationToolkit"
			teamName="luna-growth"
			fallback="unmount"
		>
			<CheckTimestamp>
				<CheckEligibilityAndToggle
					onToggle={onToggle}
					isOpen={isOpen}
					experienceId={experienceId}
				/>
			</CheckTimestamp>
		</JSErrorBoundary>
	);
}

function CheckEligibilityAndToggle({
	onToggle,
	isOpen,
	experienceId,
}: AutoOpenInsightsPanelIfEligibleProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isEligible = useIsEligibleAutoOpenInsightsPanel();
	const { updateAutoOpenTimestamp } = useAutoOpenTimestampActions();

	useEffect(() => {
		if (!isEligible) {
			return;
		}

		updateAutoOpenTimestamp();

		if (isOpen) {
			return;
		}

		onToggle(
			createAnalyticsEvent({
				actionSubject: `${INSIGHTS_PANEL}.${EXPERIMENT_NAME}`,
				action: 'expanded',
			}),
			experienceId,
		);
	}, [isEligible, onToggle, isOpen, experienceId, createAnalyticsEvent, updateAutoOpenTimestamp]);
	return null;
}

function CheckTimestamp({ children }: { children: ReactNode }) {
	const { hasBeenFourteenDays } = useAutoOpenTimestampState();

	// we only want to auto-open the insights panel once per trial. Since we only show the experiment in the last week of the trial, 14 days is sufficient for both the Standard trial and Premium trial cases.
	if (!hasBeenFourteenDays) {
		return null;
	}
	return children;
}
