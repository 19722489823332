import React from 'react';
import { styled } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';

type Props = {
	children: string;
};

const Label = ({ children }: Props) => {
	// naive solution to check whether the text is too long and might be truncated with ellipsis
	const shouldShowTooltip = children.length > 16;

	return shouldShowTooltip ? (
		<Tooltip content={children} tag="span">
			<Content>{children}</Content>
		</Tooltip>
	) : (
		<Content>{children}</Content>
	);
};

export default Label;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.span({
	display: 'block',
	textAlign: 'left',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
});
