import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { READ_VIEW_CONTAINER_SELECTOR } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { READ_VIEW_CONTAINER_SELECTOR as originalReadViewContainer } from '@atlassian/jira-issue-field-original-estimate/src/common/constants.tsx';
import { CARD_CONTAINER_SELECTOR } from '@atlassian/jira-platform-board-kit/src/common/constants/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const EstimateWrapperOld = styled.div<{ hasValue: boolean }>({
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ hasValue }) => (hasValue ? 'flex' : 'none'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
		bottom: token('space.150', '12px'),
		position: 'absolute',
		zIndex: 3, // to compete with ICC in between cards
	},

	/* undo hide if card is focused or hovered */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`${CARD_CONTAINER_SELECTOR}:hover &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:active &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:focus &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:focus-within &`]: {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const EstimateWrapperNew = styled.div<{ hasValue: boolean }>({
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ hasValue }) => (hasValue ? 'flex' : 'none'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
		position: 'absolute',
		zIndex: 3, // to compete with ICC in between cards
	},

	/* undo hide if card is focused or hovered */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`${CARD_CONTAINER_SELECTOR}:hover &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:active &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:focus &,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${CARD_CONTAINER_SELECTOR}:focus-within &`]: {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export const EstimateWrapper = componentWithFG(
	'fix_jira_board_card_nested_interactive_elements',
	EstimateWrapperNew,
	EstimateWrapperOld,
);
