/** @jsx jsx */
import React, { type ReactElement, useState, type RefObject, useEffect } from 'react';
import { styled, css, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import InfoIcon from '@atlaskit/icon/glyph/info';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Popup from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { Message as MessageType } from '../../../common/types';

const inlineMessageContentStyles = css({
	width: '200px',
	textAlign: 'left',
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
});

const messageStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& + &': {
		'&::before': {
			content: ' ',
			display: 'block',
			marginTop: token('space.200', '16px'),
			marginBottom: token('space.200', '16px'),
			height: token('space.025', '2px'),
			width: '100%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.border', colors.N30),
		},
	},
});

const OPACITY = 0.9;
const iconWrapperStyles = css({
	'&:hover': {
		opacity: OPACITY,
	},
});

const getColor = (type: 'info' | 'warning') => {
	const types = {
		info: token('color.icon.discovery', colors.P300),
		warning: token('color.icon.warning', colors.Y300),
	};

	return types[type];
};

type Props = {
	infos?: MessageType[];
	warnings?: MessageType[];
	innerRef: RefObject<HTMLDivElement>;
};

const typesMapping = {
	info: {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		icon: InfoIcon,
		defaultLabel: 'info inline message',
	},
	warning: {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		icon: WarningIcon,
		defaultLabel: 'warning inline message',
	},
};

const InlineMessages = ({ infos = [], warnings = [], innerRef }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			setIsOpen(false);
		};

		window.addEventListener('scroll', onScroll, true);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	const renderMessage = (title: string, description: string, index: number): ReactElement => (
		<div key={index} css={messageStyles}>
			{title && (
				<p>
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<strong>{title}</strong>
				</p>
			)}
			{description && <p>{description}</p>}
		</div>
	);

	const renderInlineMessage = (messages: MessageType[], type: 'info' | 'warning') => {
		const {
			[type]: { icon: SelectedIcon, defaultLabel },
		} = typesMapping;

		return (
			<Wrapper data-testid={`platform-inline-card-create.ui.form.inline-messages-${type}`}>
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-end"
					content={() => (
						<div css={inlineMessageContentStyles} ref={innerRef}>
							{messages.map(
								({ title, description }, index) =>
									(title || description) && renderMessage(title, description, index),
							)}
						</div>
					)}
					trigger={(triggerProps) => (
						<Button
							{...triggerProps}
							appearance="subtle-link"
							spacing="none"
							onClick={() => setIsOpen(!isOpen)}
						>
							<span
								css={iconWrapperStyles}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{ opacity: isOpen ? OPACITY : 1, color: getColor(type) }}
							>
								<SelectedIcon label={defaultLabel} size="medium" />
							</span>
						</Button>
					)}
				/>
			</Wrapper>
		);
	};

	if (warnings.length > 0) {
		return renderInlineMessage(warnings, 'warning');
	}
	if (infos.length > 0) {
		return renderInlineMessage(infos, 'info');
	}

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	paddingLeft: token('space.100', '8px'),
	marginRight: token('space.negative.025', '-2px'),
});

export default InlineMessages;
