import React, { type ComponentPropsWithRef, forwardRef, memo, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Box, xcss } from '@atlaskit/primitives';
import { PopupSelect, components, type OptionProps } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { TOOLTIP_DELAY } from '../../../common/constants';
import type { Project } from '../../../common/types';
import messages from './messages';
import type { SelectRef, Props, ProjectOption } from './types';

const ANALYTICS_SUBJECT = 'iccProjectSelect';

const Option = ({ children, ...props }: OptionProps<ProjectOption>) => {
	const { projectInfo } = props.data;

	return (
		<components.Option {...props}>
			<OptionWrapper data-testId="platform-inline-card-create.ui.form.project-select.option">
				<AvatarWrapper>
					<Avatar src={projectInfo.avatar} size="xsmall" />
				</AvatarWrapper>
				<LabelWrapper>{projectInfo.name}</LabelWrapper>
			</OptionWrapper>
		</components.Option>
	);
};

const transformOptions = (projects: Project[], selectedProjectId?: number | null | undefined) => {
	let value;
	const options: ProjectOption[] = [];

	projects.forEach((project) => {
		const option = {
			label: project.name,
			value: project.id,
			projectInfo: project,
		};

		if (project.id === selectedProjectId) {
			value = option;
		} else {
			options.push(option);
		}
	});

	if (!value) {
		value = null;
	}

	return { options, value };
};

const ProjectSelect = forwardRef<SelectRef, Props>(
	({ projects, selectedProjectId, onSelectProject }, ref) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const { value, options } = useMemo(
			() => transformOptions(projects, selectedProjectId),
			[projects, selectedProjectId],
		);

		const trigger = useCallback(
			({ isOpen, ...triggerProps }: { isOpen: boolean }) => {
				const { label, projectInfo } = value || {
					label: undefined,
					projectInfo: { avatar: '', name: '', key: '' },
				};
				return (
					<Box xcss={projectContainerStyles}>
						<Box xcss={projectButtonWrapperStyles}>
							<Tooltip content={label ?? null} delay={TOOLTIP_DELAY} hideTooltipOnClick>
								<Button
									{...triggerProps}
									appearance="subtle"
									spacing="none"
									isSelected={isOpen}
									aria-label={formatMessage(messages.ariaLabel, {
										value: label ?? formatMessage(messages.invalid),
									})}
									testId="platform-inline-card-create.ui.form.project-select.trigger"
								>
									<OptionWrapper>
										{isNil(projectInfo) ? (
											<PlaceholderWrapper>{formatMessage(messages.placeholder)}</PlaceholderWrapper>
										) : (
											<>
												<AvatarWrapper>
													<Avatar src={projectInfo.avatar || ''} size="xsmall" />
												</AvatarWrapper>
												<LabelWrapper>{projectInfo.key}</LabelWrapper>
												<ChevronDownIcon label="" />
											</>
										)}
									</OptionWrapper>
								</Button>
							</Tooltip>
						</Box>
					</Box>
				);
			},
			[formatMessage, value],
		);

		const onChange = useCallback(
			(option: ProjectOption | null) => {
				option && onSelectProject(option.projectInfo.id);

				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'dropdown changed',
						actionSubject: ANALYTICS_SUBJECT,
					}),
					{},
				);
			},
			[createAnalyticsEvent, onSelectProject],
		);

		const onMenuOpen = useCallback(() => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'dropdown opened',
					actionSubject: ANALYTICS_SUBJECT,
				}),
				{},
			);
		}, [createAnalyticsEvent]);

		const setRefNode = useCallback(
			(popupSelectRef: PopupSelect<ProjectOption>) => {
				ref && popupSelectRef && set(ref, 'current.node', popupSelectRef);
			},
			[ref],
		);

		return (
			<PopupSelect
				ref={setRefNode}
				components={{ Option }}
				target={trigger}
				options={options}
				value={value}
				onChange={onChange}
				onMenuOpen={onMenuOpen}
				isSearchable={false}
				// DSP-2990 - PopupSelect doesn't respect isSearchable, searchThreshold is required
				searchThreshold={Number.MAX_SAFE_INTEGER}
				minMenuWidth="auto"
				classNamePrefix="inline-card-create-project-select"
				testId="platform-inline-card-create.ui.form.project-select.project-select"
			/>
		);
	},
);

const projectContainerStyles = xcss({
	display: 'flex',
	minWidth: '0px',
	position: 'relative',
});

const projectButtonWrapperStyles = xcss({
	width: '100%',
	paddingLeft: 'space.100',
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderWrapper = styled.span({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	color: token('color.text.subtlest', '#626f86'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
	paddingLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.span({
	marginRight: token('space.050', '4px'),
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.span({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	minWidth: 0,
});

export default memo<
	JSX.LibraryManagedAttributes<typeof ProjectSelect, ComponentPropsWithRef<typeof ProjectSelect>>
>(ProjectSelect);

export type { SelectRef, Props, Project } from './types';
