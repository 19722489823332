import type { ProjectType } from '@atlassian/jira-common-constants';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import {
	type ProjectId,
	type ProjectKey,
	toProjectId,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';

type ProjectServiceResponse = {
	projectKey: ProjectKey;
	projectId: ProjectId;
	projectType: ProjectType;
	isSimplified: boolean;
};

export const useProjectService = (): ProjectServiceResponse | null => {
	const [{ data: projectData }] = useContainerContext();
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	if (projectData && projectData.project) {
		return {
			// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
			projectKey: toProjectKey(projectData.project.key),
			// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
			projectId: toProjectId(`${projectData.project.id}`),
			// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
			projectType: projectData.project.type,
			// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
			isSimplified: projectData.project.isSimplified,
		};
	}
	return null;
};
