import React, { forwardRef, type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AutomationGlyph } from '../../common/assets/automation';
import messages from './messages';
import type { Props } from './types';

const MenuButton = forwardRef<HTMLElement, Props>(
	({ isSelected, onClick, intl: { formatMessage } }: Props, ref) => {
		const onClickWithAnalytics = useCallback(
			(event: SyntheticEvent<HTMLElement>, analytics: UIAnalyticsEvent) => {
				fireUIAnalytics(analytics, 'automationMenuButton');
				onClick();
			},
			[onClick],
		);

		return (
			<Tooltip content={formatMessage(messages.menuButtonTooltip)} position="top">
				<Button
					ref={ref}
					appearance="subtle"
					spacing="none"
					isSelected={isSelected}
					onClick={onClickWithAnalytics}
					data-testId="automation-menu.ui.button.menu-button"
					id="automation-menu.ui.popup"
					// @ts-expect-error - externalId does not exist on Button types
					externalId="automation-menu.ui.button.menu-button"
					aria-label={formatMessage(messages.menuButtonTooltip)}
					aria-expanded={isSelected}
					iconBefore={
						<IconWrapper>
							<Icon
								size="medium"
								glyph={AutomationGlyph}
								label={formatMessage(messages.automationsMenuButtonIcon)}
								primaryColor={isSelected ? token('color.icon.selected', colors.N20) : undefined}
								secondaryColor="none"
							/>
						</IconWrapper>
					}
				/>
			</Tooltip>
		);
	},
);

export default MenuButton;

const unselectedColor = token('color.text.subtle', colors.N500);
const unselectedHoverColor = token('color.text.subtlest', colors.N200);

const multiplier = 4;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * multiplier}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * multiplier}px`,
	color: unselectedColor,
	'&:hover': {
		color: unselectedHoverColor,
	},
});
