import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabel: {
		id: 'inline-card-create.form.project-select.aria-label',
		defaultMessage: "The current project is {value}. Press the 'Space' key to change the project.",
		description: 'An aria-label describing what the select element allows to change.',
	},
	invalid: {
		id: 'inline-card-create.form.project-select.invalid',
		defaultMessage: 'invalid',
		description: 'A label that represents an invalid value for an project',
	},
	placeholder: {
		id: 'inline-card-create.form.project-select.placeholder',
		defaultMessage: 'Select project',
		description: 'A placeholder for project select',
	},
});
