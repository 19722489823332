import React, { type ComponentType, memo, useState, type ReactNode } from 'react';
import isNil from 'lodash/isNil';
import { withEngagement } from '@atlassian/jira-engagement';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import InlineIssueCreate, {
	FULL,
	VISIBLE,
	type TriggerAppearance,
	type StatusTransition,
	type CreatePayload,
} from '@atlassian/jira-platform-inline-card-create';
import type { Project } from '@atlassian/jira-platform-inline-card-create/src/ui/form/project-select/types';
import { FIELD_KEY_STATUS_TRANSITIONS } from '@atlassian/jira-platform-inline-card-create/src/ui/form/status-select/index.tsx';
import type { Props as TypeSelectProps } from '@atlassian/jira-platform-inline-card-create/src/ui/form/type-select/index.tsx';
import { HiddenButtonWrapper } from './button';
import type {
	CreateCallback,
	CardTypeShape,
	ChangeFormVisibleCallback,
	TriggerClickCallback,
	CancelCallback,
	BlurCallback,
	ChangeProjectCallback,
	AutoOpenCallback,
} from './types';

export type Props = {
	shouldStayOpen: boolean;
	isFormVisible?: boolean;
	appliedFilterCount: number;
	cardTypes: CardTypeShape[];
	showOnHover: boolean;
	triggerAppearance: TriggerAppearance;
	buttonSpotlightTargetId?: string;
	manageIssueTypesLink: string | null;
	selectedCardTypeId?: number | undefined;
	defaultCardTypeId?: string | number | undefined;
	renderAdditionalTypeSelectOptionContent?: (cardType: CardTypeShape) => ReactNode;
	onCreate: CreateCallback;
	onChangeFormVisible: ChangeFormVisibleCallback;
	onTriggerClick: TriggerClickCallback;
	onCancel: CancelCallback;
	onBlur: BlurCallback;
	statusTransitions: StatusTransition[];
	TypeSelect?: ComponentType<TypeSelectProps>;
	/* shouldKeepFormOpenOnClickOutside: For issue type create in context experimentation only, prevent icc to be closed on click outside */
	shouldKeepFormOpenOnClickOutside?: boolean;
	shouldShowCreateButton?: boolean;
	// Start of props for Increment Planning board
	isIPBoard?: boolean;
	projects?: Project[];
	initialProjectId?: number;
	onChangeProject?: ChangeProjectCallback;
	// End of props for Increment Planning board
	shouldScrollIntoView?: boolean;
	onAutoOpen?: AutoOpenCallback;
};

export const InlineCardCreateWithEngagement = withEngagement(InlineIssueCreate);

const isEnrolledInSimplifyIccBundle = () => {
	const [expConfigM2] = UNSAFE_noExposureExp('simplify_icc_bundle_m2');

	const isEnrolledM2 = expConfigM2.get('cohort', 'not-enrolled') !== 'not-enrolled';
	return isEnrolledM2;
};

const InlineCardCreate = (props: Props) => {
	const [isFormOpen, setIsFormOpen] = useState(props.shouldStayOpen);

	if (__SERVER__) {
		return null;
	}

	const {
		shouldStayOpen,
		isFormVisible: isFormVisibleProp,
		cardTypes,
		showOnHover,
		triggerAppearance,
		buttonSpotlightTargetId,
		manageIssueTypesLink,
		appliedFilterCount,
		selectedCardTypeId,
		defaultCardTypeId,
		onTriggerClick,
		onChangeFormVisible,
		onCancel,
		onBlur,
		onCreate,
		onChangeProject,
		renderAdditionalTypeSelectOptionContent,
		statusTransitions,
		isIPBoard,
		projects,
		initialProjectId,
		TypeSelect,
		shouldKeepFormOpenOnClickOutside,
		shouldShowCreateButton,
		shouldScrollIntoView,
		onAutoOpen,
	} = props;

	const onCreateHandler = (createPayload: CreatePayload) => {
		const createCallbackPayload = createPayload.map(
			({ summary, cardTypeId, fields, projectId }) => {
				const project =
					ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && isIPBoard
						? { projectId }
						: {};
				return {
					summary,
					cardType: String(cardTypeId),
					transition: fields?.get(FIELD_KEY_STATUS_TRANSITIONS),
					...project,
				};
			},
		);

		onCreate(createCallbackPayload);
	};

	const onChangeFormVisibleHandler = (isFormVisible: boolean) => {
		onChangeFormVisible(isFormVisible);
		setIsFormOpen(isFormVisible);
	};

	const IICComponent = (
		<InlineCardCreateWithEngagement
			// @ts-expect-error - TS2322 - Type '{ onAutoOpen: AutoOpenCallback | undefined; { cardTypes: CardTypeShape[]; extraItems: { url: string; }[]; numFilters: number; onCreate: (createPayload: CreatePayload) => void; appearance: "full"; triggerAppearance: TriggerAppearance; ... 10 more ...; renderAdditionalTypeSelectOptionContent: ((cardType: CardTypeShape) => ReactElement<...>) | undefined; }' is not assignable to type 'IntrinsicAttributes & WithEngagementProps & { children?: ReactNode; }'.
			cardTypes={cardTypes}
			shouldKeepFormOpenOnClickOutside={shouldKeepFormOpenOnClickOutside}
			extraItems={
				isNil(manageIssueTypesLink)
					? []
					: [
							{
								url: manageIssueTypesLink,
							},
						]
			}
			TypeSelect={TypeSelect}
			numFilters={appliedFilterCount}
			onCreate={onCreateHandler}
			appearance={FULL}
			triggerAppearance={triggerAppearance}
			areActionButtonsVisible
			isFormVisibleDefault={shouldStayOpen}
			isFormVisible={isEnrolledInSimplifyIccBundle() ? isFormVisibleProp : undefined}
			isMultiCreateEnabled={fg('disable-icc-multi-create-4d2daa44') ? false : !isIPBoard}
			targetId={buttonSpotlightTargetId}
			selectedCardTypeId={selectedCardTypeId}
			defaultCardTypeId={defaultCardTypeId}
			onChangeFormVisible={onChangeFormVisibleHandler}
			onTriggerClick={onTriggerClick}
			onCancel={onCancel}
			onBlur={onBlur}
			renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
			statusTransitions={statusTransitions}
			projects={projects}
			initialProjectId={initialProjectId}
			onChangeProject={onChangeProject}
			shouldShowCreateButton={shouldShowCreateButton}
			{...(ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1') && {
				shouldScrollIntoView,
			})}
			{...(ff('icc-experience-enhancements') && { onAutoOpen })}
		/>
	);

	return showOnHover ? (
		<HiddenButtonWrapper isICCFormOpen={isFormOpen}>{IICComponent}</HiddenButtonWrapper>
	) : (
		IICComponent
	);
};

InlineCardCreate.defaultProps = {
	shouldStayOpen: false,
	appliedFilterCount: 0,
	cardTypes: [],
	statusTransitions: [],
	showOnHover: false,
	triggerAppearance: VISIBLE,
	buttonSpotlightTargetId: '',
	manageIssueTypesLink: null,
	selectedCardTypeId: undefined,
	defaultCardTypeId: undefined,
};

export default memo<Props>(InlineCardCreate);
