import { styled } from '@compiled/react';
import { Section } from '@atlaskit/menu';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const errorImageHeight = gridSize * 14;

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 46}px`,
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorStateContainer = styled(Container)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ canManageAutomations }: { canManageAutomations: boolean }) =>
		canManageAutomations // Use negative margin to reduce whitespace when the Footer component is shown
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${gridSize * -4}px ${gridSize * 2}px`
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`0 ${gridSize * 2}px`,
});
