import { useState, useEffect } from 'react';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';

const PROJECT_ADMIN_PERMISSION = 'ADMINISTER_PROJECTS';

const fetchData = async (projectId: ProjectId): Promise<boolean> => {
	const response = await fetchJson(
		`/rest/api/2/mypermissions?permissions=${PROJECT_ADMIN_PERMISSION}&projectId=${projectId}`,
	);
	if (response && response.permissions && response.permissions[PROJECT_ADMIN_PERMISSION]) {
		return Boolean(response.permissions[PROJECT_ADMIN_PERMISSION].havePermission);
	}
	return false;
};

export const usePermissionService = (projectId: ProjectId | null) => {
	const [isProjectAdmin, setIsProjectAdmin] = useState(false);

	const isGlobalAdmin = useIsAdmin();

	useEffect(() => {
		if (!isGlobalAdmin && projectId != null) {
			fetchData(projectId).then(setIsProjectAdmin);
		}
	}, [isGlobalAdmin, projectId]);

	return {
		isProjectAdmin: isProjectAdmin || isGlobalAdmin,
	};
};
