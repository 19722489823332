import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'inline-card-create.trigger.label',
		defaultMessage: 'Create issue',
		description:
			'Label of a button that will open the inline card create form. It is also used for the tooltip of the button.',
	},
});
