import React from 'react';
import Icon from '@atlaskit/icon';
import Spinner from '@atlaskit/spinner';
import type {
	ManualRule,
	ManualRuleExecutor,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import {
	MenuIcon,
	TriggerIcon,
	ManualTriggerGlyph,
} from '@atlassian/jira-automation-platform/src/common/ui/icon/index.tsx';
import { createAri, type Ari } from '@atlassian/jira-platform-ari';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import MenuLink from '../../../../../../common/ui/menu-link/main.tsx';
import { useProjectAri } from '../../../../../../controllers/menu-context';
import { useSelectedIssues } from '../../../../../../controllers/selected-issues';

const isRuleDisabled = (
	rule: ManualRule,
	isExecuting: boolean,
	noIssuesSelected: boolean,
	crossProjectSelection: boolean,
	projectAri: Ari,
): boolean => {
	if (isExecuting || noIssuesSelected) {
		return true;
	}
	if (crossProjectSelection) {
		const scopeAris = rule.ruleScope.resources;
		// Disable if we have a cross project selection AND the rule is a SINGLE project rule.
		return scopeAris.length === 1 && scopeAris.includes(projectAri);
	}
	return false;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	isExecuting,
	rule,
	executeRule,
	selectedIssueIds,
}: {
	isExecuting: boolean;
	rule: ManualRule;
	executeRule: ManualRuleExecutor;
	selectedIssueIds?: number[];
}) => {
	const [{ selectedIssuesKeys, crossProjectSelection }] = useSelectedIssues();
	const [projectAri] = useProjectAri();
	const cloudId = useCloudId();
	const selectedIssueAris = (selectedIssueIds ?? []).map((id) =>
		createAri({
			resourceOwner: 'jira',
			resourceType: 'issue',
			cloudId,
			resourceId: id.toString(),
		}),
	);

	return (
		<MenuLink
			itemKey="MANUAL_RULE"
			isDisabled={isRuleDisabled(
				rule,
				isExecuting,
				selectedIssuesKeys.length === 0,
				crossProjectSelection,
				projectAri,
			)}
			iconBefore={
				isExecuting ? (
					<MenuIcon>
						<Spinner size="small" />
					</MenuIcon>
				) : (
					<TriggerIcon>
						<Icon size="small" glyph={ManualTriggerGlyph} label="" />
					</TriggerIcon>
				)
			}
			onClick={() => executeRule(rule.id, selectedIssueAris)}
		>
			{rule.name}
		</MenuLink>
	);
};
