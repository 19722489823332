import { useCallback } from 'react';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { fireEmbeddedConfluenceAnalytics } from './utils/analytics';

const getParentProduct = (projectType?: string) => {
	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			return 'JSM';
		case SOFTWARE_PROJECT:
			return 'JSW';
		case CORE_PROJECT:
			return 'JWM';
		default:
			return 'JSW';
	}
};

export type TrackEmbeddedConfluence = (params: {
	action: string;
	actionSubject?: string;
	eventName: string;
	projectType?: string;
	embeddedConfluenceSource?: string;
	eventType?: string;
	userHasConfluenceAccess?: boolean;
	tenantHasConfluence?: boolean;
	crossJoinStatus?: string;
	originProduct?: string;
	requestedProduct?: string;
}) => void;

export const useEmbeddedPageTracking = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tenantContext = useTenantContext();
	const source = useAnalyticsSource();

	const trackEmbeddedConfluence: TrackEmbeddedConfluence = useCallback(
		({
			eventName,
			action,
			actionSubject,
			projectType,
			embeddedConfluenceSource = 'jiraIssueView',
			eventType = 'ui',
			userHasConfluenceAccess,
			tenantHasConfluence,
			crossJoinStatus,
			originProduct,
			requestedProduct,
		}) => {
			const analyticsEvent = createAnalyticsEvent({
				action,
				actionSubject: actionSubject ?? `${eventName}`,
			});
			const attributes = {
				parentProduct: getParentProduct(projectType),
				tenantEditions: tenantContext?.appEditions,
				productEntitlementDetails: tenantContext?.productEntitlementDetails,
				embeddedConfluenceSource,
				jiraIssueSurfaceArea: source,
				userHasConfluenceAccess,
				tenantHasConfluence,
				crossJoinStatus,
				originProduct,
				requestedProduct,
			};
			fireEmbeddedConfluenceAnalytics({
				analyticsEvent,
				attributes,
				eventType,
				eventName: `${actionSubject ?? eventName} ${action}`,
			});
		},
		[source, tenantContext, createAnalyticsEvent],
	);

	return trackEmbeddedConfluence;
};
