import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { useDirectToStandardTrial } from '../direct-to-standard-trial';
import { useGetResponseAndFireAnalytics } from './get-response-and-fire-analytics';

export function useCheckExperimentEligibility(shouldFireExposure = true) {
	const { software: jswEdition } = useAppEditions();
	const productEntitlementDetails = useProductEntitlementDetails();
	const { premiumTrialOnSignup, isReady: isDoneFetchingPremiumTrialOnSignup } =
		useFetchOnboardingReverseTrial();
	const { isDirectToStandardTrial, isReady: isDoneFetchingDirectToStandardTrial } =
		useDirectToStandardTrial();
	const jswEntitlement = productEntitlementDetails?.[SOFTWARE];

	return useGetResponseAndFireAnalytics({
		isDoneFetchingDirectToStandardTrial,
		isDoneFetchingPremiumTrialOnSignup,
		shouldFireExposure,
		jswEdition,
		jswEntitlement,
		premiumTrialOnSignup,
		isDirectToStandardTrial,
	});
}
