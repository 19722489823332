import React from 'react';
import { styled } from '@compiled/react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ManualTriggerGlyph = (props: any) => (
	<svg {...props} width={24} viewBox="0 0 24 24">
		<path
			d="m21.192 19.778-4.9498-4.9498c-0.3905-0.3905-1.0237-0.3905-1.4142 0-0.3905 0.3906-0.3905 1.0237 0 1.4143l4.9498 4.9497c0.3905 0.3905 1.0237 0.3905 1.4142 0s0.3905-1.0237 0-1.4142z"
			fill="currentColor"
		/>
		<path
			d="m8.7738 8.1319 11.685 4.305c0.2591 0.0955 0.3917 0.3829 0.2963 0.6421-0.025 0.0679-0.0645 0.1295-0.1156 0.1807l-7.3802 7.3801c-0.1952 0.1953-0.5118 0.1953-0.7071 0-0.0511-0.0512-0.0906-0.1128-0.1156-0.1807l-4.3051-11.685c-0.09546-0.25911 0.03721-0.54656 0.29632-0.64202 0.11157-0.0411 0.23414-0.0411 0.34571 0z"
			clipRule="evenodd"
			fill="currentColor"
			fillRule="evenodd"
		/>
		<path
			d="m4.2218 4.2218c1.9526-1.9526 5.1184-1.9526 7.0711 0 0.5977 0.59776 1.0125 1.3092 1.2443 2.0648 0.1974 0.6437-0.4389 1.1502-1.0873 0.96899l-0.2172-0.06069c-0.3475-0.09709-0.6037-0.38008-0.7563-0.70702-0.1439-0.30836-0.3432-0.59728-0.59773-0.85184-1.1716-1.1716-3.0711-1.1716-4.2426 0-1.1716 1.1716-1.1716 3.0711 0 4.2426 0.2547 0.25469 0.54381 0.45399 0.85236 0.59799 0.32725 0.1527 0.61051 0.4091 0.70752 0.7569l0.06045 0.2168c0.18082 0.6483-0.32561 1.2843-0.96914 1.087-0.75578-0.2317-1.4675-0.6466-2.0654-1.2445-1.9526-1.9526-1.9526-5.1184 0-7.071z"
			clipRule="evenodd"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/design-system/no-legacy-icons
export const ManageRulesGlyph = (props: any) => <ShortcutIcon size="small" {...props} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutomationGlyph = (props: any) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.3789 4.62256C9.99914 4.62173 9.65331 4.84087 9.49192 5.18458L6.11523 12.3762C5.97308 12.679 5.9956 13.0334 6.17494 13.3157C6.35428 13.598 6.66553 13.7691 7 13.7691H8.79182L6.40251 18.1304C6.17135 18.5523 6.28163 19.0798 6.66249 19.3738C7.04335 19.6677 7.58152 19.6408 7.93117 19.3103L16.8269 10.902C17.1178 10.6269 17.2117 10.2023 17.0637 9.83032C16.9157 9.45832 16.5558 9.21418 16.1555 9.21418H14.547L17.0353 6.24031C17.2787 5.94947 17.3319 5.5441 17.1717 5.20034C17.0116 4.85658 16.6671 4.63646 16.2879 4.63562L10.3789 4.62256Z"
			stroke="currentColor"
			strokeWidth="1.95489"
			strokeLinejoin="round"
		/>
	</svg>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutomationGlyphWhite = (props: any) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.1838 4.78749C10.2664 4.61167 10.4433 4.49957 10.6375 4.5L17.0011 4.51406C17.1951 4.51449 17.3713 4.62709 17.4532 4.80294C17.5352 4.97879 17.508 5.18614 17.3835 5.33492L13.9446 9.44482H16.8597C17.0645 9.44482 17.2486 9.5697 17.3243 9.76C17.4 9.95029 17.352 10.1675 17.2032 10.3082L7.62319 19.3634C7.44433 19.5324 7.16903 19.5462 6.97421 19.3958C6.77938 19.2454 6.72297 18.9756 6.84122 18.7598L9.86254 13.2448H7C6.82891 13.2448 6.66969 13.1573 6.57795 13.0129C6.48621 12.8685 6.47469 12.6872 6.54741 12.5323L10.1838 4.78749Z"
			fill={token('color.text.inverse', 'white')}
		/>
	</svg>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutomationGlyphBlack = (props: any) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.1838 4.78749C10.2664 4.61167 10.4433 4.49957 10.6375 4.5L17.0011 4.51406C17.1951 4.51449 17.3713 4.62709 17.4532 4.80294C17.5352 4.97879 17.508 5.18614 17.3835 5.33492L13.9446 9.44482H16.8597C17.0645 9.44482 17.2486 9.5697 17.3243 9.76C17.4 9.95029 17.352 10.1675 17.2032 10.3082L7.62319 19.3634C7.44433 19.5324 7.16903 19.5462 6.97421 19.3958C6.77938 19.2454 6.72297 18.9756 6.84122 18.7598L9.86254 13.2448H7C6.82891 13.2448 6.66969 13.1573 6.57795 13.0129C6.48621 12.8685 6.47469 12.6872 6.54741 12.5323L10.1838 4.78749Z"
			fill={token('color.text', 'black')}
		/>
	</svg>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MenuIcon = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '4px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TriggerIcon = styled(MenuIcon)({
	color: token('color.icon.accent.green', '#23A06C'),
	backgroundColor: token('color.background.accent.green.subtlest', '#DFFCF0'),
});
