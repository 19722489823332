import { useCallback } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { LOCAL_STORAGE_LAST_ENFORCED_KEY } from '../../common/constants';
import { localStorage } from '../../common/utils';
import { useEnforceStorageModal } from '../store';

type showEnforcementIfStorageIsFullParams = {
	delayShow?: boolean;
	shouldOnlyShowInJSWContext?: boolean;
	touchpoint: string;
};

const MODAL_COOLDOWN_MS = 86400000; // 1 day in ms

export const useStorageEnforcement = () => {
	const [currentState, { setIsOpen: setEnforceStorageModalIsOpen }] = useEnforceStorageModal();
	const issueViewMode = useViewMode();
	const { data: projectContextData } = useProjectContext();
	const isInJSWProject = projectContextData?.projectType === SOFTWARE_PROJECT;

	const openModalIfNotOnCooldown = useCallback(
		(touchpoint: string) => {
			const isOnCooldown =
				Date.now() - (localStorage.get(LOCAL_STORAGE_LAST_ENFORCED_KEY) || 0) < MODAL_COOLDOWN_MS;

			if (isOnCooldown) {
				if (currentState.isOpen === 'pending') {
					// Reset pending state if modal is on cooldown when it should be shown
					setEnforceStorageModalIsOpen({ isOpen: 'closed', touchpoint: undefined });
				}
			} else {
				setEnforceStorageModalIsOpen({ isOpen: 'open', touchpoint });
			}
		},
		[setEnforceStorageModalIsOpen, currentState.isOpen],
	);

	const showEnforcementIfStorageIsFull = useCallback(
		({
			delayShow = false,
			shouldOnlyShowInJSWContext = true,
			touchpoint,
		}: showEnforcementIfStorageIsFullParams) => {
			if (shouldOnlyShowInJSWContext && !isInJSWProject) {
				return;
			}

			// Issue view use case is checked internally here rather than passed in, to avoid polluting consumer code
			if (delayShow || issueViewMode === 'MODAL') {
				setEnforceStorageModalIsOpen({
					isOpen: 'pending',
					touchpoint,
				});
			} else {
				openModalIfNotOnCooldown(touchpoint);
			}
		},
		[isInJSWProject, issueViewMode, setEnforceStorageModalIsOpen, openModalIfNotOnCooldown],
	);

	// Used to show enforcement modal after modals are dismissed, to avoid modal on modal UX
	const showEnforcementIfPendingToShow = useCallback(() => {
		if (currentState.isOpen === 'pending') {
			// Use the trigger from created the JSW issue
			openModalIfNotOnCooldown(currentState.touchpoint);
		}
	}, [currentState.isOpen, openModalIfNotOnCooldown, currentState.touchpoint]);

	return { showEnforcementIfStorageIsFull, showEnforcementIfPendingToShow };
};
