import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'inline-card-create.form.multi-create-dialog.header',
		defaultMessage: 'Want a hand with that?',
		description:
			'Header of the dialog that prompts users to choose between create single/multiple issue(s) when new lines are detected',
	},
	body: {
		id: 'inline-card-create.form.multi-create-dialog.body',
		defaultMessage:
			'If you want, we can create an issue for every new line ({summariesNo} in total)',
		description:
			'Body of the dialog that prompts users to choose between create single/multiple issue(s) when new lines are detected',
	},
	tooManyIssuesBody: {
		id: 'inline-card-create.form.multi-create-dialog.too-many-issues-body',
		defaultMessage:
			'If you want, we can create an issue for every new line (up to {maxNoSummaries} at a time)',
		description:
			'Alternative body for the dialog that prompts users to choose between create single/multiple issue(s) when new lines are detected. Alerts the user of the limit to the number of issues that can be created this way',
	},
	createSingle: {
		id: 'inline-card-create.form.multi-create-dialog.create-single',
		defaultMessage: 'Create one issue',
		description: 'Create single issue button to create an issue using a concatenated summary',
	},
	createMultiple: {
		id: 'inline-card-create.form.multi-create-dialog.create-multiple',
		defaultMessage: 'Create {summariesNo} issues',
		description: 'Create multiple issues button to create issues using split summaries',
	},
	multiCreateDisabledHeader: {
		id: 'inline-card-create.form.multi-create-dialog.multi-create-disabled-header',
		defaultMessage: 'Multiple issues can’t be created',
		description:
			'Header of the dialog that prompts when the issue type has required fields and multiple issue create is disabled',
	},
	multiCreateDisabledBody: {
		id: 'inline-card-create.form.multi-create-dialog.multi-create-disabled-body',
		defaultMessage:
			'For issue types with required fields, multiple issues can’t be created at once.\n' +
			'Select another issue type, or create each issue individually.',
		description:
			'Body of the dialog that prompts when the issue type has required fields and multiple issue create is disabled',
	},
	cancelButton: {
		id: 'inline-card-create.form.multi-create-dialog.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Ok Button that closes the dialog',
	},
});
