// eslint-disable-next-line jira/restricted/react-component-props
import React, { useEffect, type ComponentProps, type ComponentType } from 'react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services';
import { ColumnCreate as PlatformColumnCreate } from '@atlassian/jira-platform-board-kit';
import {
	fireUIAnalytics,
	AnalyticsEventToProps,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux';
import { BoardCreateColumnButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/board-create-column/async.tsx';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardCreateColumnKanbanTourNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-create-column/async.tsx';
import { useBoardSelector } from '../../../../state';
import { columnCreateRequest } from '../../../../state/actions/column/create';
import type { State } from '../../../../state/reducers/types';
import { makeValidateColumnSelector } from '../../../../state/selectors/column/column-selectors';
import { getIsCMPBoard } from '../../../../state/selectors/software/software-selectors';
import { isSprintsEnabled } from '../../../../state/selectors/sprint/sprint-selectors';
import { isAnyColumnUpdating } from '../../../../state/selectors/work/work-selectors';
import type { Dispatch } from '../../../../state/types';

type OwnProps = {
	isCMPBoard: boolean;
};

type Props = {
	isKanbanBoard: boolean;
} & ComponentProps<typeof PlatformColumnCreate>;

const connector = connect(
	(state: State) => ({
		// analytics
		externalId: 'software.board.column.add.title',
		onEditStart: noop,
		onEditCancel: noop,
		validate: makeValidateColumnSelector(state),
		isDisabled: isAnyColumnUpdating(state),
		isKanbanBoard: !isSprintsEnabled(state),
	}),
	(dispatch: Dispatch, ownProps: OwnProps) => ({
		onSubmit: (temporaryColumnId: number, name: string, analyticsEvent: UIAnalyticsEvent) => {
			const action = columnCreateRequest(
				temporaryColumnId,
				name,
				analyticsEvent,
				ownProps.isCMPBoard,
			);
			dispatch(action);
		},
		onEditStart: (analyticsEvent: UIAnalyticsEvent) => fireUIAnalytics(analyticsEvent),
		onEditCancel: (columnId: number, type: string, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, { type, columnId }),
	}),
);

// Wraps ColumnCreate with a NudgeToolTip
export const withQuickstartNudge =
	<P extends Props>(WrappedComponent: ComponentType<P>): ComponentType<P> =>
	(ownProps: P) => {
		const AsyncNudgeComponent = ownProps.isKanbanBoard
			? BoardCreateColumnKanbanTourNudgeAsync
			: BoardCreateColumnButtonWrapperNudgeAsync;
		const [isOnboardingDismissed] = useIsDismissed();

		return (
			<ConditionalNudgeWrapper
				conditionsToApplyWrapper={
					fg('board_create_column_nudge_conditional_load') ? !isOnboardingDismissed : true
				}
				Wrapper={AsyncNudgeComponent}
			>
				<Inline grow="hug">
					<WrappedComponent {...ownProps} />
				</Inline>
			</ConditionalNudgeWrapper>
		);
	};

const ColumnCreate = flow(
	withQuickstartNudge,
	AnalyticsEventToProps('columnCreate', {
		onEditStart: 'editStarted',
		onEditCancel: 'editCanceled',
	}),
	connector,
)(PlatformColumnCreate);

const ColumnCreateWrapper = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	useEffect(() => {
		if (isCMPBoard) {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalytics(analyticsEvent, 'columnCreateButton mounted');
		}
	}, [isCMPBoard, createAnalyticsEvent]);

	return <ColumnCreate isCMPBoard={isCMPBoard} />;
};

export default ColumnCreateWrapper;
