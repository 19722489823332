/* eslint-disable jira/react/no-style-attribute */
/** @jsx jsx */
import React, { type CSSProperties, memo } from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import type { CardType } from '../../../../common/types';

export type Props = {
	cardType?: CardType;
	/**
	 * Custom styles overwrites the rendered icon "img"
	 */
	styles?: CSSProperties;
	/**
	 * @deprecated the className prop is used by styled elements to style this component
	 */
	className?: string;
};

export const GENERIC_ICON_URL = '/icons/issuetypes/genericissue.svg';

const cardTypeImageStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
});

const TypeIcon = ({ cardType, styles, className }: Props) =>
	cardType ? (
		<img
			css={cardTypeImageStyles}
			src={cardType.iconUrl}
			alt={cardType.name}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={styles}
		/>
	) : (
		<img
			alt=""
			css={cardTypeImageStyles}
			src={GENERIC_ICON_URL}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={styles}
		/>
	);

export default memo<Props>(TypeIcon);
