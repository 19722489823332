import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SingleLineSmartLinkEntryPoint } from './entrypoint';
import type { SingleLineSmartLinkProps } from './types';

const entryPointParams = {};

export const LazySingleLineSmartLink = (props: SingleLineSmartLinkProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		SingleLineSmartLinkEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jira.issue-field.single-line-smart-link"
			packageName="issue-field-smart-link"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
