import type {
	FULL,
	COMPACT,
	SIMPLE,
	HIDDEN,
	VISIBLE,
	TABLE_ROW,
	BLUR,
	ENTER,
	CREATE_BUTTON,
	MULTI_DIALOG_SINGLE_CREATE_BUTTON,
	MULTI_DIALOG_MULTI_CREATE_BUTTON,
} from './constants';

export type FieldKey = string;
export type FieldValue = string | number | null;
export type FieldValues = Map<FieldKey, FieldValue>;

// Do not use SIMPLE and TABLE_ROW. They are custom appearances for epic panel and roadmap respectively.
export type CardAppearance = typeof FULL | typeof COMPACT | typeof SIMPLE | typeof TABLE_ROW;
export type CreatedBy =
	| typeof ENTER
	| typeof BLUR
	| typeof CREATE_BUTTON
	| typeof MULTI_DIALOG_SINGLE_CREATE_BUTTON
	| typeof MULTI_DIALOG_MULTI_CREATE_BUTTON;

export type CreateMetadata = {
	createdBy: CreatedBy;
};
export type CreatePayload = {
	summary: string;
	cardTypeId: number | string;
	fields?: FieldValues;
	projectId?: number;
}[];
export type TriggerAppearance = typeof HIDDEN | typeof VISIBLE;

export type ValidationError = {
	summary: string;
	summaryConcat: string;
};

export const emptyValidationError: ValidationError = {
	summary: '',
	summaryConcat: '',
};

export type Message = {
	title: string;
	description: string;
};

export type CardType = {
	id: number | string;
	name: string;
	iconUrl?: string;
	hasRequiredFields?: boolean | null;
	isDisabled?: boolean;
};

export type StatusTransition = {
	transitionId: number;
	name: string;
	statusId: number;
	isInitial: boolean;
	statusCategoryId: number;
};

export type ExtraItem = {
	url: string;
	name?: string;
	onItemSelect?: () => void;
	openNewTab?: boolean;
};

export type Project = {
	id: number;
	avatar: string;
	key: string;
	name: string;
	projectTypeKey: string;
};
