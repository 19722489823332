import React from 'react';
import Icon from '@atlaskit/icon';
import CreateRuleIcon from '@atlaskit/icon/glyph/add';
import AuditLogIcon from '@atlaskit/icon/glyph/check-circle-outline';
import { Section } from '@atlaskit/menu';
import { Footer as FooterAfe } from '@atlassian/automation-manual-triggers';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ManageRulesGlyph, MenuIcon } from '../../common/ui/icon';
import MenuLink from '../../common/ui/menu-link/main.tsx';
import messages from './messages';

interface Props {
	baseAutomationUrl: string;
	hasSeparator?: boolean;
}

export const FooterOld = ({ baseAutomationUrl, hasSeparator = true }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<Section hasSeparator={hasSeparator}>
			<MenuLink
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({
							actionSubject: 'link',
							action: 'clicked',
						}),
						'manualRulesActionsMenu.adminFooter.createAutomation',
						{ userType: 'admin' },
					);
				}}
				itemKey="CREATE_RULE"
				href={`${baseAutomationUrl}#rule/new/__NEW__TRIGGER?componentFilterCriteria=QUICK_ACTIONS`}
				iconBefore={
					<MenuIcon>
						<CreateRuleIcon size="small" label="" />
					</MenuIcon>
				}
			>
				{formatMessage(messages.createAutomationItem)}
			</MenuLink>
			<MenuLink
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({
							actionSubject: 'link',
							action: 'clicked',
						}),
						'manualRulesActionsMenu.adminFooter.manageAutomations',
						{ userType: 'admin' },
					);
				}}
				itemKey="MANAGE_RULES"
				href={baseAutomationUrl}
				iconBefore={
					<MenuIcon>
						<Icon size="small" glyph={ManageRulesGlyph} label="" />
					</MenuIcon>
				}
			>
				{formatMessage(messages.manageAutomationsItem)}
			</MenuLink>
			<MenuLink
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({
							actionSubject: 'link',
							action: 'clicked',
						}),
						'manualRulesActionsMenu.adminFooter.auditLog',
						{ userType: 'admin' },
					);
				}}
				itemKey="AUDIT_LOG"
				href={`${baseAutomationUrl}#tab/audit-log`}
				iconBefore={
					<MenuIcon>
						<AuditLogIcon size="small" label="" />
					</MenuIcon>
				}
			>
				{formatMessage(messages.auditLogItem)}
			</MenuLink>
		</Section>
	);
};

export const FooterNew = ({ baseAutomationUrl }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<FooterAfe
			baseAutomationUrl={baseAutomationUrl}
			onCreateAutomationFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.createAutomation', {
					userType: 'admin',
				});
			}}
			onManageAutomationsFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.manageAutomations', {
					userType: 'admin',
				});
			}}
			onAuditLogFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.auditLog', {
					userType: 'admin',
				});
			}}
		/>
	);
};

export default componentWithFF(
	'xpa-1198-consume-afe-footer-and-send-analytics-for-footer-and-empty-state_lzknx',
	FooterNew,
	FooterOld,
);
