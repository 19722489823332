import { useState, useEffect } from 'react';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import type { Trait } from '@atlassian/jira-personalization-service/src/types.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { EXPERIMENT_NAME, ONE_DAY_IN_MS, ONE_MINUTE_IN_MS } from '../../common/constants';
import { useServiceTrackEvent } from '../analytics';

export const useDirectToStandardTrial = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jsw_presentation_toolkit_experiment');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { fireServiceTrackEvent } = useServiceTrackEvent();
	const productEntitlementDetails = useProductEntitlementDetails();
	const { software: jswEdition } = useAppEditions();
	const cloudId = useCloudId();
	const cohort = expConfig.get('cohort', NOT_ENROLLED);
	const jswEntitlement = productEntitlementDetails?.[SOFTWARE];
	const trialEndTimeMs = jswEntitlement?.trialEndTime;
	const [isReady, setIsReady] = useState(false);
	const [isDirectToStandardTrial, setIsDirectToStandardTrial] = useState(false);

	useEffect(() => {
		if (cohort === NOT_ENROLLED || jswEdition !== STANDARD_EDITION || !trialEndTimeMs) {
			setIsReady(true);
			setIsDirectToStandardTrial(false);
			return;
		}

		const fetchJSWActivationDateTrait = async () => {
			try {
				setIsReady(false);
				const siteTraits = await memoizedFetchSitePersonalization({ cloudId });
				setIsDirectToStandardTrial(isJSWDirectToTrial(siteTraits, trialEndTimeMs));
				setIsReady(true);
				fireServiceTrackEvent({ service: 'useDirectToStandardTrial' });
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'jptUseDirectToTrial failed', {
					experiment: EXPERIMENT_NAME,
					cohort,
					error,
					statusCode: error?.statusCode,
					errorMessage: error?.message,
				});
				// if the request fails we will assume they are direct to standard trial to be safe
				setIsDirectToStandardTrial(true);
				setIsReady(true);
			}
		};

		fetchJSWActivationDateTrait();
	}, [cloudId, trialEndTimeMs, createAnalyticsEvent, cohort, jswEdition, fireServiceTrackEvent]);

	return { isReady, isDirectToStandardTrial };
};

const isJSWDirectToTrial = (traits: Trait[], trialEndTimeMs: number): boolean => {
	const jswActivationDateTrait = traits.find(
		(trait) => trait.name === 'jira-software.ondemand.activationDate',
	);

	if (!jswActivationDateTrait?.value) {
		return false;
	}

	if (typeof jswActivationDateTrait.value !== 'string') {
		return false;
	}

	const jswActivationDate = new Date(jswActivationDateTrait.value);
	const trialStartDate = new Date(trialEndTimeMs - 14 * ONE_DAY_IN_MS);
	// give 5 minute buffer in case of delay in system
	return trialStartDate.getTime() - jswActivationDate.getTime() < ONE_MINUTE_IN_MS * 5;
};
