import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';

type IssueOriginalEstimateInlineEditErrorBoundaryProps = {
	fallback: ReactNode;
	children?: ReactNode;
	packageName: string;
	onError?: () => void;
};

export const OriginalEstimateInlineEditErrorBoundary = ({
	fallback,
	children,
	packageName,
	onError,
}: IssueOriginalEstimateInlineEditErrorBoundaryProps) => (
	<JSErrorBoundary
		id="original-estimate-inline-edit"
		packageName={packageName}
		teamName="bento"
		fallback="flag"
		onError={onError}
	>
		<Placeholder name="original-estimate-inline-edit-error-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);
