import { useMemo } from 'react';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { STANDARD_EDITION, PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { EntitlementDetails } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { EXPERIMENT_NAME, ONE_DAY_IN_MS } from '../../common/constants';
import { useOperationalExposureEvent } from '../analytics';

export interface UseGetResponseAndFireAnalyticsArgs {
	shouldFireExposure: boolean;
	isDoneFetchingPremiumTrialOnSignup: boolean;
	isDoneFetchingDirectToStandardTrial: boolean;
	jswEdition: string;
	jswEntitlement?: EntitlementDetails;
	premiumTrialOnSignup?: boolean;
	isDirectToStandardTrial: boolean;
}

export function useGetResponseAndFireAnalytics({
	isDoneFetchingPremiumTrialOnSignup,
	isDoneFetchingDirectToStandardTrial,
	shouldFireExposure,
	jswEdition,
	jswEntitlement,
	premiumTrialOnSignup,
	isDirectToStandardTrial,
}: UseGetResponseAndFireAnalyticsArgs) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { fireOperationalExposureEvent } = useOperationalExposureEvent();

	return useMemo(() => {
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [expConfig, fireExposure] = UNSAFE_noExposureExp('jsw_presentation_toolkit_experiment');
		const cohort = expConfig.get('cohort', NOT_ENROLLED);
		if (!isDoneFetchingPremiumTrialOnSignup || !isDoneFetchingDirectToStandardTrial) {
			return { isEligible: false, isLoading: true, cohort };
		}

		const trialEndTime = jswEntitlement?.trialEndTime;
		const isLastWeekOfTrial = isInLastWeekOfTrial(trialEndTime);
		const isEligible =
			cohort !== NOT_ENROLLED &&
			(jswEdition === STANDARD_EDITION || jswEdition === PREMIUM_EDITION) &&
			!!trialEndTime &&
			isLastWeekOfTrial &&
			!premiumTrialOnSignup &&
			!isDirectToStandardTrial;

		let ineligibilityReason;

		if (cohort === NOT_ENROLLED) {
			ineligibilityReason = 'cloudIdNotEnrolled';
		} else if (trialEndTime && !isLastWeekOfTrial) {
			ineligibilityReason = 'beforeLastWeekOfTrial';
		} else if (trialEndTime && premiumTrialOnSignup) {
			ineligibilityReason = 'premiumReverseTrial';
		} else if (isDirectToStandardTrial) {
			ineligibilityReason = 'standardDirectToTrial';
		}

		if (isEligible || ineligibilityReason) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'jptEligibility checked', {
				experiment: EXPERIMENT_NAME,
				cohort,
				isEligible,
				ineligibilityReason,
			});
		}

		if (isEligible && shouldFireExposure) {
			fireExposure();
			fireOperationalExposureEvent();
		}

		return { isEligible, isLoading: false, cohort };
	}, [
		isDoneFetchingPremiumTrialOnSignup,
		isDoneFetchingDirectToStandardTrial,
		isDirectToStandardTrial,
		jswEdition,
		premiumTrialOnSignup,
		jswEntitlement,
		shouldFireExposure,
		createAnalyticsEvent,
		fireOperationalExposureEvent,
	]);
}

const isInLastWeekOfTrial = (trialEndTime?: number) => {
	if (trialEndTime === undefined) {
		return false;
	}

	return trialEndTime - Date.now() <= 7 * ONE_DAY_IN_MS;
};
