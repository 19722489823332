import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import OperationButton from './ecosystem-operation/view';
import type { Operation } from './types';

export type Props = {
	operations: Operation[];
	onModalClose: () => void;
};

export default function BoardOperations(props: Props) {
	if (getWillShowNav4()) {
		if (props.operations.length === 0) {
			return null;
		}
		return (
			<>
				{props.operations.map((op) => (
					<OperationButton key={op.url} operation={op} onModalClose={props.onModalClose} />
				))}
			</>
		);
	}
	return (
		<>
			{props.operations.map((op) => (
				<OperationWrapper key={op.url}>
					<OperationButton operation={op} onModalClose={props.onModalClose} />
				</OperationWrapper>
			))}
		</>
	);
}

BoardOperations.defaultProps = {
	operations: [],
	onModalClose: noop,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OperationWrapper = styled.div({
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: '0',
	},
});
