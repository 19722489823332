import React from 'react';
import CustomIcon, { SVG, type CustomIconProps } from '../../../../../common/ui/custom-icon';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const SecurityGlyph: React.FunctionComponent = () => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px">
		<path
			fill="#000"
			fillRule="nonzero"
			d="M10.778 5.04 5.38 7.74l.556 5.007a5.555 5.555 0 0 0 2.293 3.908l2.549 1.82V5.04Zm2.222 0v13.435l2.55-1.82a5.556 5.556 0 0 0 2.292-3.908l.556-5.008L13 5.04ZM3 6.444 11.889 2l8.889 4.444-.728 6.549a7.778 7.778 0 0 1-3.21 5.47l-4.305 3.076a1.111 1.111 0 0 1-1.292 0l-4.306-3.076a7.778 7.778 0 0 1-3.21-5.47L3 6.444Z"
		/>
	</SVG>
);
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

const SecurityIcon: React.FunctionComponent<CustomIconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <SecurityGlyph />} />
);

export default SecurityIcon;
