import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fetchCardCoversService } from '../../services/card-covers';
import { transformCardCoversRestApiResponse } from '../../services/card-covers/card-covers-transformer';
import { cardMediaDataSet } from '../../state/actions/card';
import {
	FETCH_CARD_COVERS,
	type FetchCardCoversAction,
	fetchCardCoversFailure,
} from '../../state/actions/card-covers';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

// Used to retrieve card cover data for CMP boards
export const getCardCoversEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$
		.ofType(FETCH_CARD_COVERS)
		.throttleTime(1000)
		.switchMap((action: FetchCardCoversAction) => {
			const state = store.getState();
			// if there are no issue IDs in the payload, fetch card covers for all of the issue IDs on the board
			const issueIds =
				action.payload.length > 0 ? action.payload : boardOrderedIssueIdsSelector(state);
			const analyticsEvent = action.meta?.analyticsEvent;

			if (issueIds.length === 0) {
				return Observable.of();
			}
			return fetchCardCoversService(issueIds)
				.mergeMap((response) => {
					if (analyticsEvent) {
						fireOperationalAnalytics(analyticsEvent, 'cardCovers fetchSucceeded', {
							task: 'cardCoversFetch',
						});
					}
					return Observable.of(
						cardMediaDataSet(transformCardCoversRestApiResponse(response, issueIds)),
					);
				})
				.catch((error) => {
					if (!isClientFetchError(error)) {
						fireErrorAnalytics({
							event: analyticsEvent,
							error,
							meta: {
								id: 'board.cardCovers.fetch.failure',
								packageName: 'jiraSoftwareBoard',

								teamName: 'A4T-Pandora',
							},
						});
					}
					return Observable.of(fetchCardCoversFailure());
				});
		});
