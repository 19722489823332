import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createButtonLabel: {
		id: 'inline-card-create.form.create-button.create-button-label',
		defaultMessage: 'Create',
		description: 'The label for the create button in the inline card create form.',
	},
	createButtonTooltip: {
		id: 'inline-card-create.form.create-button.create-button-tooltip',
		defaultMessage: 'Create issue <icon>return icon</icon>',
		description: 'Tooltip that appears when the mouse is hovered over the create button.',
	},
	createButtonTooltipContentEmpty: {
		id: 'inline-card-create.form.create-button.create-button-tooltip-content-empty',
		defaultMessage: 'Type an issue summary',
		description:
			'Tooltip that appears when the mouse is hovered over the create button but the button is disabled because the user has not entered an issue summary yet.',
	},
});
