import React, { forwardRef, useCallback, useRef } from 'react';
import Button, {
	CustomThemeButton,
	type ButtonProps,
	type CustomThemeButtonProps,
} from '@atlaskit/button';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useModalDialogActions } from '../../controllers';

type Props = ButtonProps | CustomThemeButtonProps | IconButtonProps;
type OldProps = ButtonProps | CustomThemeButtonProps;

/**
 * When clicked, a reference to this button will be stored,
 * allowing focus to return to it upon the dismissal of a
 * modal. Works in tandem with ModalDialog that is exposed
 * via @atlassian/jira-software-modal-dialog.
 *
 * Accepts either ButtonProps OR CustomThemeButtonProps
 * as per @atlaskit/button
 */
const ModalTriggerButtonNew = forwardRef<HTMLButtonElement | null, Props>((props, ref) => {
	const internalRef = useRef<HTMLButtonElement | null>(null);
	const { setReturnFocusTo } = useModalDialogActions();

	const handleRef = useCallback(
		(element: HTMLButtonElement) => {
			if (ref) {
				if (typeof ref === 'function') {
					ref(element);
				} else {
					// We need to proxy ref to avoid an eslint error (no-param-reassign)
					const mutableRef = ref;
					mutableRef.current = element;
				}
			}
			internalRef.current = element;
		},
		[ref],
	);

	const isIconProp = 'icon' in props;
	const onClickHandler = !isIconProp ? props.onClick : undefined;
	const iconButtonOnClickHandler = isIconProp ? props.onClick : undefined;

	const onClick = useCallback(
		(...args: Parameters<NonNullable<ButtonProps['onClick']>>) => {
			if (!isIconProp) {
				onClickHandler?.(...args);
				setReturnFocusTo(internalRef);
			}
		},
		[isIconProp, setReturnFocusTo, internalRef, onClickHandler],
	);

	const onIconButtonClick = useCallback(
		(...args: Parameters<NonNullable<IconButtonProps['onClick']>>) => {
			if (isIconProp) {
				iconButtonOnClickHandler?.(...args);
				setReturnFocusTo(internalRef);
			}
		},
		[isIconProp, setReturnFocusTo, internalRef, iconButtonOnClickHandler],
	);

	if ('icon' in props) {
		return <IconButton {...props} ref={handleRef} onClick={onIconButtonClick} />;
	}

	if ('theme' in props) {
		return <CustomThemeButton {...props} ref={handleRef} onClick={onClick} />;
	}

	return <Button {...props} ref={handleRef} onClick={onClick} />;
});

const ModalTriggerButtonOld = forwardRef<HTMLButtonElement | null, OldProps>(
	({ onClick: onClickProp, ...props }, ref) => {
		const internalRef = useRef<HTMLButtonElement | null>(null);
		const { setReturnFocusTo } = useModalDialogActions();

		const handleRef = useCallback(
			(element: HTMLButtonElement) => {
				if (ref) {
					if (typeof ref === 'function') {
						ref(element);
					} else {
						// We need to proxy ref to avoid an eslint error (no-param-reassign)
						const mutableRef = ref;
						mutableRef.current = element;
					}
				}
				internalRef.current = element;
			},
			[ref],
		);

		const onClick = useCallback(
			(...args: Parameters<NonNullable<ButtonProps['onClick']>>) => {
				onClickProp && onClickProp(...args);
				setReturnFocusTo(internalRef);
			},
			[onClickProp, setReturnFocusTo],
		);

		if ('theme' in props) {
			return <CustomThemeButton ref={handleRef} onClick={onClick} {...props} />;
		}

		return <Button ref={handleRef} onClick={onClick} {...props} />;
	},
);

export const ModalTriggerButton = componentWithFG(
	'tnk-1708-forms-builder-meatball-modal-focus',
	ModalTriggerButtonNew,
	ModalTriggerButtonOld,
);
