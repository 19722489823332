import React from 'react';
import CustomIcon, { SVG, type CustomIconProps } from '../../../../../common/ui/custom-icon';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const SlackGlyph: React.FunctionComponent = () => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px">
		<g fill="none" fillRule="nonzero">
			<path
				fill="#E01E5A"
				d="M6.258 14.613c0 1.145-.935 2.081-2.08 2.081a2.086 2.086 0 0 1-2.081-2.08c0-1.146.935-2.081 2.08-2.081h2.081v2.08ZM7.306 14.613c0-1.145.936-2.08 2.081-2.08s2.08.935 2.08 2.08v5.21c0 1.145-.935 2.08-2.08 2.08a2.086 2.086 0 0 1-2.08-2.08v-5.21Z"
			/>
			<path
				fill="#36C5F0"
				d="M9.387 6.258a2.086 2.086 0 0 1-2.08-2.08c0-1.146.935-2.081 2.08-2.081s2.08.935 2.08 2.08v2.081h-2.08ZM9.387 7.306c1.145 0 2.08.936 2.08 2.081s-.935 2.08-2.08 2.08h-5.21a2.086 2.086 0 0 1-2.08-2.08c0-1.145.935-2.08 2.08-2.08h5.21Z"
			/>
			<path
				fill="#2EB67D"
				d="M17.742 9.387c0-1.145.936-2.08 2.08-2.08 1.146 0 2.082.935 2.082 2.08s-.936 2.08-2.081 2.08h-2.08v-2.08ZM16.693 9.387c0 1.145-.935 2.08-2.08 2.08a2.086 2.086 0 0 1-2.081-2.08v-5.21c0-1.145.935-2.08 2.08-2.08 1.146 0 2.081.935 2.081 2.08v5.21Z"
			/>
			<path
				fill="#ECB22E"
				d="M14.613 17.742c1.145 0 2.081.936 2.081 2.08 0 1.146-.936 2.082-2.08 2.082a2.086 2.086 0 0 1-2.081-2.081v-2.08h2.08ZM14.613 16.693a2.086 2.086 0 0 1-2.08-2.08c0-1.146.935-2.081 2.08-2.081h5.21c1.145 0 2.08.935 2.08 2.08 0 1.146-.935 2.081-2.08 2.081h-5.21Z"
			/>
		</g>
	</SVG>
);
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

const SlackIcon: React.FunctionComponent<CustomIconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <SlackGlyph />} />
);

export default SlackIcon;
