import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabel: {
		id: 'inline-card-create.form.status-select.aria-label',
		defaultMessage: "The current status is {value}. Press the 'Space' key to change the status.",
		description: 'An aria-label describing what the select element allows to change.',
	},
	invalid: {
		id: 'inline-card-create.form.status-select.invalid',
		defaultMessage: 'invalid',
		description: 'A label that represents an invalid value for an issue type',
	},
});
