import React, { useMemo, forwardRef } from 'react';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import { Box, Inline, Stack, type BackgroundColor } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	DROPDOWN,
} from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/jira-router';
import { AutomationGlyph } from '../../common/assets/automation';
import { AUTOMATION_RECOMMENDATIONS_PREFIX, PACKAGE_NAME } from '../../common/constants';
import type { TemplateRecommendation } from '../../common/types';
import { SOURCE_NAME } from './constants';
import { ExplainerSection } from './explainer-section';
import messages from './messages';
import { TemplateSection } from './template-section';
import { findHighestPriorityTemplate } from './utils';

type Props = {
	baseAutomationUrl: string;
	templates: TemplateRecommendation[];
	setClosed: () => void;
};

// atlaskit button passes type="button" to the component you specify which makes it render a button, this prevents that
const ForceLink = forwardRef((props: React.ComponentProps<typeof Link>, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	<Link ref={ref as any} {...props} type="a" />
));

const UnsafeAutomationRecommendation = ({ baseAutomationUrl, templates, setClosed }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const template = useMemo(() => findHighestPriorityTemplate(templates), [templates]);

	if (!template) return null;

	const handleBuildRuleClick = () => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' });
		fireUIAnalytics(analyticsEvent, `${AUTOMATION_RECOMMENDATIONS_PREFIX}.buildRuleLink`, {
			templateId: template.id.value,
		});
		setClosed();
	};

	const handleTemplatesClick = () => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' });
		fireUIAnalytics(analyticsEvent, `${AUTOMATION_RECOMMENDATIONS_PREFIX}.templatesLink`, {
			templateId: template.id.value,
		});
		setClosed();
	};

	const ruleUrl = `${baseAutomationUrl}#/rule/template?templateId=${template.id.value}`;

	return (
		<ContextualAnalyticsData
			sourceName={SOURCE_NAME}
			sourceType={DROPDOWN}
			attributes={{ templateId: template.id.value }}
		>
			<FireScreenAnalytics />
			<Box
				padding="space.250"
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				backgroundColor={'color.background.information' as BackgroundColor}
			>
				<Stack space="space.200">
					<ExplainerSection templateId={template.id.value} />
					<TemplateSection template={template} />
					<Inline alignBlock="center" space="space.150">
						<Button
							appearance="primary"
							spacing="compact"
							iconBefore={
								<Icon
									size="medium"
									glyph={AutomationGlyph}
									label=""
									primaryColor={token('color.icon.inverse', colors.N20)}
									secondaryColor="none"
								/>
							}
							component={ForceLink}
							href={ruleUrl}
							onClick={handleBuildRuleClick}
						>
							{formatMessage(messages.createRule)}
						</Button>
						<Link href={`${baseAutomationUrl}#/tab/rule-library#`} onClick={handleTemplatesClick}>
							{formatMessage(messages.viewTemplates)}
						</Link>
					</Inline>
				</Stack>
			</Box>
		</ContextualAnalyticsData>
	);
};

export const AutomationRecommendation = (props: Props) => (
	<JSErrorBoundary
		id={`${AUTOMATION_RECOMMENDATIONS_PREFIX}.panel`}
		packageName={PACKAGE_NAME}
		fallback="unmount"
	>
		<UnsafeAutomationRecommendation {...props} />
	</JSErrorBoundary>
);
