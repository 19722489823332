import React from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const LoadingStandupPanel = () => (
	<Flex xcss={spinnerWrapperStyles}>
		<Spinner size="large" />
	</Flex>
);

const spinnerWrapperStyles = xcss({
	height: '100%',
	marginLeft: 'space.300',
	zIndex: 'card',
	width: '422px',
	background: token('elevation.surface'),
	borderRadius: 'border.radius.300',
	border: `1px solid ${token('color.border')}`,
	marginRight: 'space.negative.300',
	alignItems: 'center',
	justifyContent: 'center',
});
