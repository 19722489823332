import type { ReactElement } from 'react';
import { NOT_ENROLLED } from '@atlassian/jira-common-util-get-experiment-group';
import { fg } from '@atlassian/jira-feature-gating';
import { useExperimentCohort } from '../../services/experiment-cohort/index.tsx';

export default function ExperimentGate({ children }: { children: ReactElement }) {
	const cohort = useExperimentCohort(NOT_ENROLLED);
	const isPassedGate = fg('jsw_presentation_toolkit_gate');

	if (cohort === NOT_ENROLLED || !isPassedGate) {
		return null;
	}

	return children;
}
